<template lang="pug">
div.select-image-view
  .row
    .col-8
      multiselect(
        :key="'select-image-'+ itemKey"
        v-model="image",
        label="file_name",
        track-by="id",
        :options="options",
        placeholder="搜尋影音庫檔案編號或標題關鍵字",
        @search-change="updateOptions"
      )
        template(slot="singleLabel", slot-scope="{ option }")
          img.option__image(:key="option.id + '-option-image'" v-if="type === 'image'" :src="option.url")
          span.option__desc(:key="option.id + '-option-title'")
            span.option__title {{ option.file_name }}

        template(slot="option", slot-scope="{ option }")
          img.option__image(:key="option.id + '-option-image'" v-if="type === 'image'" :src="option.url")
          .option__desc(:key="option.id + '-option-title'")
            span.option__title {{ option.file_name }}
            //- span.option__small {{ option.des }}
    .col-4
      b-button(
        block,variant="primary",size="md",
        @click="$bvModal.show('mediaLibraryTools' + itemKey)"
      ) 點我開啟圖庫

    //- 圖片庫系統
    b-modal(
      :id="'mediaLibraryTools' + itemKey",
      size="lg",
      title="封面圖圖庫選擇器",
      title-class="font-18",
      hide-footer
    )
      //- 圖片上傳區域
      div.modal-body
        p 圖片上傳
        b-form-file(
          @change="onFileChange"
        )

        .loading-status(v-if="uploadFileStatus")
          b-spinner(type="grow" variant="primary")

      //- 圖片列表區域
      div.modal-body(v-if="!uploadFileStatus")
        p 圖片列表
        .input-group
          input.form-control(
            type='text' 
            placeholder="請輸入圖片檔名關鍵字" 
            aria-label="請輸入圖片檔名關鍵字" 
            aria-describedby='basic-addon2'
            v-model="search_data.name"
          )
          .input-group-append
            button.btn.btn-dark.waves-effect.waves-light(type="button",@click="getImageFileList") 搜尋

        //- 圖片搜尋與清單
        .img-list
          //- 圖片列表
          .image-list-container.pt-2.pb-3.row
            .loading-status(v-if="loadImageStatus")
              b-spinner(type="grow" variant="primary")
            .col-lg-2.p-2(v-for="(file,index) in file_list", :key="index")
              img.image-library-item(
                :src="file.url" 
                @click="insertImageOnCurrentCursor(file)"
              )       
              .image-name {{ file.file_name }}    

          //- 分頁區域
          div.dataTables_paginate.paging_simple_numbers.float-right(v-if="totalRows > 0")
            ul.pagination.pagination-rounded.mb-0
              b-pagination(v-model="search_data.page", :total-rows="totalRows", :per-page="perPage")

</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect
  },
  props: ['itemKey', 'value', 'type', 'index'],
  data () {
    return {
      image: this.value,
      options: [],
      search_data: {
        name: '',
        type: 1,
        source: 0,
        sort_by: 1,
        sort_method: 'DESC',
        page: 1,
        per_page: 24
      },
      loadImageStatus: false,
      uploadFileStatus: false,
      file_list: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
    }
  },
  watch: {
    'image' (_image) {
      let _index = this.index
      console.log('--> [select-image] 選擇圖片:', _image, _index);
      this.$emit('upDateImage', _image, _index)
    },
    'value_compute' () {
      this.image = this.value_compute
    },
    'search_data.page' () {
      this.getImageFileList()
    },
  },
  computed: {
    value_compute () {
      return this.value
    }
  },
  methods: {
    customLabel ({ title, desc }) {
      return `${title} – ${desc}`
    },
    updateOptions (_keyword) {
      let mediaType
      let vm = this
      this.options = []

      // 圖片型態檔案
      if (this.type === 'image') {
        mediaType = 1
      }

      // 影音型態檔案
      else if (this.type === 'video') {
        mediaType = 2
      }

      // console.log("=> 圖庫搜尋:", _keyword)
      this.$root.apis.getFileList({
        name: _keyword,
        type: mediaType
      }, function (_response) {
        let files = _response.data.files
        // console.log('=> 需要更新圖片清單: ' + files.length + "組");
        files.forEach(function (_image) {
          vm.options.push(_image)
        })
        // console.log('=> vm.options:', vm.options)
      })
    },
    // 獲得檔案列表
    getImageFileList () {
      this.file_list = []
      this.loadImageStatus = true
      this.$root.apis.getFileList(this.search_data, this.getFileListSuccess)
    },
    getFileListSuccess (_response) {
      this.file_list = _response.body.files
      this.totalRows = _response.body.total
      this.currentPage = _response.body.page
      this.perPage = _response.body.per_page
      this.loadImageStatus = false
    },
    // 接收到檔案上傳事件
    onFileChange (_event) {
      console.log('--> [封面圖選擇] 接收到檔案上傳事件:', _event);
      // 圖片上傳網址
      let upload_uri = this.$root.upload_uri + "/media/create/files/default"

      // 獲取選擇的檔案
      let file = _event.target.files[0];
      if (!file) return;

      // 創建 FormData 對象
      let formData = new FormData();
      formData.append('files[]', file);

      // 使用 axios 或 fetch 發送上傳請求
      this.uploadFileStatus = true
      fetch(upload_uri, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': 'Bearer ' + this.$root.common.getCookie('api-token')
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log('--> [封面圖選擇] 上傳成功:', data);
          this.uploadFileSuccess(data);
        })
        .catch(error => {
          console.error('--> [封面圖選擇] 上傳失敗:', error);
        });
    },
    uploadFileSuccess (_response) {
      this.uploadFileStatus = false
      console.log('--> [封面圖選擇] 上傳檔案成功:', _response);
      // 在這裡可以處理上傳成功後的邏輯
      if (_response.code === '0000') {
        this.insertImageOnCurrentCursor(_response.data[0])
      }
    },
    insertImageOnCurrentCursor (_image) {
      // 獲取 iframe 的實際源
      console.log('--> [封面圖選擇] 請求選定圖片:', _image);
      // 更新選擇的圖片
      this.image = _image
      // 關閉圖庫元件
      this.$bvModal.hide('mediaLibraryTools' + this.itemKey)
    }
  }
}
</script>
<style scoped>
.option__image {
  width: 100px;
}

.loading-status {
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding-top: 33%;
  padding-bottom: 33%;
}

.img-list .image-name {
  height: 20px;
  width: 100%;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}

img.image-library-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
}
</style>
